<template>
  <div>
    <el-dialog
      :title="`${formatText(action)} Additional Feedback`"
      :visible.sync="setShow"
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Feedback" prop="feedback">
              <el-input
                type="textarea"
                v-model="form.feedback"
                :autosize="{ minRows: 4, maxRows: 6 }"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" @click="update" :loading="updating"
          >{{ action === "add" ? "Add feedback" : "Save changes" }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import payment from "@/requests/gardeners/payment-tracker";

export default {
  name: "PaymentFeedbackForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        feedback: "",
      },
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.form.feedback = this.customer.feedback;
        }
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form.feedback = "";
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;

        const payload = {
          tracker_id: this.customer.tracker_id,
          additional_feedback: this.form.feedback,
        };

        payment
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$emit("success");
              this.closeEvent();
            }
            this.adding = false;
          })
          .catch((error) => {
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
</style>
